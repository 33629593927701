$white: #fff;
$black: #000;
$primary: #007cd8;
$secondary: #7e7e7e;
$success: #4caf50;
$info: #002266;
$warning: #f8da4e;
$danger: #fe484f;
$light: #efefef;
$dark: #2a2925;
$light-gray: #e8e8e8;
$gray: #989898;

$dark-gray: #5e5e5e;
$indigo: #6610f2;
$light-blue: #82e3f8;
$dark-blue: #28628d;
$tuftsBlue : #5373D8;
$harp : #E6F0EC;

$gradient-1: linear-gradient(to right, $info, $primary);

$custom-colors: (
  "white": $white,
  "black": $black,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "light-gray": $light-gray,
  "gray": $gray,
  "dark-gray": $dark-gray,
  "indigo": $indigo,
  "light-blue": $light-blue,
  "dark-blue": $dark-blue,
  "tufts-blue" : $tuftsBlue,
  "harp" :$harp
);
$custom-font-sizes: (
  7: $font-size-base * 0.75,
  8: $font-size-base * 0.5,
);
$font-sizes: map-merge($font-sizes, $custom-font-sizes);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-bg-colors: map-loop($theme-colors, rgba-css-var, "$key", "bg");
