@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 10;
  src: url("../assets/fonts/woff2/Dana-Hairline.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../assets/fonts/woff/Dana-Hairline.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/woff2/Dana-Thin.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts/woff/Dana-Thin.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 200;
  src: url("../assets/fonts/woff2/Dana-UltraLight.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../assets/fonts/woff/Dana-UltraLight.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/woff2/Dana-Light.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts/woff/Dana-Light.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/woff2/Dana-Medium.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts/woff/Dana-Medium.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/woff2/Dana-DemiBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../assets/fonts/woff/Dana-DemiBold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/woff2/Dana-ExtraBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../assets/fonts/woff/Dana-ExtraBold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/woff2/Dana-Black.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts/woff/Dana-Black.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/woff2/Dana-ExtraBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../assets/fonts/woff/Dana-ExtraBold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/woff2/Dana-Regular.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../assets/fonts/woff/Dana-Regular.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

@font-face {
  font-family: iranSans;
  src: url(../assets/fonts/sans/IRANSansWebFaNum.ttf);
}