.StartOrderUsd {
  .image-uploader {
    width: 100px;
    height: 100px;
    .bi-plus-lg {
      width: 25px;
      height: 25px;
      bottom: 1rem;
      right: 1rem;
    }
  }
}
