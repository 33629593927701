.VideoPlayer {
  // height: 400px;
  .title {
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }
  .video {
  }
  .thumbnail {
  }
  .controls {
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    .bi {
      width: 25px;
      height: 25px;
      position: relative;
      font-size: 1.25rem;
      color: white;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: currentColor;
        opacity: 0;
        border-radius: 50%;
        transition: opacity 250ms;
        pointer-events: none;
      }
      &:hover::after {
        opacity: 0.2;
      }
    }
    input[type="range"] {
      flex: 1;
      accent-color: currentColor;
    }
  }
  .title,
  .controls {
    transition: opacity 250ms, transform 250ms;
    opacity: 0;
    pointer-events: none;
  }
  &.active:hover {
    .title,
    .controls {
      transform: translateY(0%);
      opacity: 1;
      pointer-events: all;
    }
  }
}
