.PlanCard {
    width: 100%;
    background-color: white;
    min-height: 450px;
    border: 5px solid black;

    .planCard-img {
        img {
            position: relative;
            @media (min-width : 992px){
                top: -20px;
            }
            
        }
    }
}