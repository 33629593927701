.WindowsServerTrade {
  .serverOption{

      line-height: 225%;
      li {
        list-style: disc;
        &::marker {
          color: black;
        }
        p {
          width: fit-content;
          text-align-last: start;
        }
      }
    
  }
  
  .tab-btns {
    border-radius: 0px !important;
    border: none !important;
    
    button {
      padding: 0;
      border: none !important;
      color: black !important;
      &.active {
        background-color: #5373D8 !important;
        
        .tab-btn {
          color: white !important;
          border: 2px solid black;
          border-radius: 0px !important;
        }
      }
      &:not(.active) {
        background-color: #EFEFEF !important;
        .tab-btn {
          color: black !important;
        }
      }
    }
  }
}
