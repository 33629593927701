.PermitSection {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, transparent, var(--bs-light));
    opacity: 0.3;
  }
  .img-section {
    perspective: 700px;
    img {
      transform: rotateY(30deg) scale(0.85);
    }
    &:hover img {
      transform: rotateY(0deg) scale(1);
    }
  }
}
