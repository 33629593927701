.react-select-container {
    width: 100%;
    height: fit-content;
    padding-top: 18px;
    border: none;
    .react-select__control{
        background-color: #F7F7F7 !important;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid black;
    }
    
}