.Articles {
  > .container > .row {
    > div {
      &:nth-child(1) {
        > div {
          &:nth-child(even) {
            height: 350px;
          }
          &:nth-child(odd) {
            height: 700px;
          }
        }
      }
      &:nth-child(2) {
        > div {
          &:nth-child(even) {
            height: 700px;
          }
          &:nth-child(odd) {
            height: 350px;
          }
        }
      }
    }
  }
}
