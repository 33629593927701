.Profile {
  .Profile-info {
    min-height: 310px;
  }

  .pardakht-section {
    width: 100%;
    position: relative;
    @media (min-width : 992px) {
      top: -65px;
    }
    top : -50px;
    
   
  }

  .Your-score {
    width: 100%;
    min-height: 500px;
    border: 3px solid black;

    .Identification-code {
      border: 3px dashed black;
      background: #F7F7F7;
    }
  }

  .manage-sms {
    width: 100%;
    min-height: 243px;
    background-color: #ECEDF3;
    border: 3px solid black;
  }

  .Current-plan {
    width: 100%;
    min-height: 243px;
    border: 3px solid black;
    background: #5373D8;
  }
  .showPLAN{
    min-height: 243px;
  }

  .CONDITION {
    .CONDITION-card {
      width: 100%;
      min-height: 200px;
      overflow: hidden;

      // border: 3px solid black;
      ::-webkit-scrollbar {
        width: 5px;
        margin: 0px 5px;
      }



      .CONDITION-body {
        overflow: hidden;
        border: 3px solid black;

        /* Track */
        ::-webkit-scrollbar-track {
          background: #D8D8D8;
          border-radius: 0px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #000000;
          border-radius: 0px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #000000;
        }

        .CONDITION-content {
          height: 163px;
          overflow-y: scroll;

        }

      }
    }

    .CONDITION-nextBtn {
      width: 243px;
      height: 56px;
    }
  }

  .CHOOSE-PLAN {
    .PlanCard {
      width: 100%;
      min-height: 450px;
      border: 5px solid black;

      .planCard-img {
        img {
          position: relative;
          top: -20px;
        }
      }
    }
  }

  .PAYMENT-PLAN {
    .paymentBtn {
      @media (min-width : 1200px) {
        width: 170px;
      }
      width: 100%;
      height: 100px;
      background: #F7F7F7;
      border: 1px solid #DBDBDB;
      &:active , &:focus{
        border :  2px solid black !important;
      }
    }
  }
}