.DownloadSection {
  a {
    height: 40px;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  // p {
  //   width: 100%;
  //   flex: 0 0 100%;
  // }
  // .row {
  //   flex: 0 0 100%;
  //   button {
  //     border: 1px solid;
  //     border-color: rgba(var(--bs-dark-rgb), 0.5);
  //     .icon {
  //       transition: 250ms;
  //     }
  //     &:hover {
  //       border-color: rgba(var(--bs-dark-rgb), 1);
  //       .icon {
  //         transform: translateX(-5px);
  //       }
  //     }
  //   }
  // }
  // @media (min-width: 1200px) {
  //   p {
  //     flex: 0 0 auto;
  //     width: fit-content;
  //   }
  //   .row {
  //     flex: 1;
  //   }
  // }
}
