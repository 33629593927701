.UserLayout {
  
  > main {
    min-height: calc(100vh - 270px - 6rem);
  }
  > header {
    z-index: 5;
    top: 0;
    left: 0;
    > div {
      max-width: 1800px;
      .user-control {
        .badge {
          position: absolute;
          top: -12px;
          right: -12px;
        }
      }
    }
  }
  .Navbar,
  .Menu {
    a,
    .dropdown button {
      position: relative;
      background-color: transparent;
      border-color: transparent;
      color: white;
      width: fit-content;
      white-space: nowrap;
    }
    .dropdown.hover {
      .dropdown-menu {
        opacity: 0;
        pointer-events: none;
        transform: translateY(10%);
      }
      &:hover {
        .dropdown-menu {
          opacity: 1;
          pointer-events: all;
          transform: translateY(0);
        }
      }
    }
  }
  .Navbar .nav-items {
    > a,
    > .dropdown button {
      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: black;
        transform: scaleX(0);
        transition: 250ms;
      }
      &:hover::before {
        transform: scaleX(1);
      }
    }
  }
  .Navbar {
    .dropdown {
      .dropdown-menu {
        top: 100%;
        right: 0;
      }
    }
  }
  .Menu {
    top: 0;
    left: 0;
    z-index: 10;
    background-color: transparent;
    pointer-events: none;
    .hide-btn {
      top: 0;
      left: 0;
      background-color: transparent;
      cursor: default;
    }
    nav {
      width: 300px;
      transform: translateX(100%);
    }
    &.active {
      background-color: rgba($color: #000000, $alpha: 0.25);
      pointer-events: all;
      nav {
        transform: translateX(0);
      }
    }
  }
  .FooterUser {
    // background-image: linear-gradient(to bottom, #efefef, #91a9ba);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.15);
      pointer-events: none;
    }
    > .row {
      max-width: 1800px;
      .CallInfo {
        
        &::before {
          content: "";
          @media (min-width : 992px) {            
            position: absolute;
            width: 1px;
            height: 75%;
            right: 0;
            top: 12.5%;
            background-color: #FFFFFF;
          }
        }
  
        &::after {
          content: "";
          @media (min-width : 992px) {
            top : 12.5%;
            position: absolute;
            width: 1px;
            height: 75%;
            left: 0;
            background-color: #FFFFFF;
          }
  
        }
      }
      a {
        color: var(--bs-light);
        &:hover,
        &.active {
          color: var(--bs-info);
        }
      }
    }
  }
}
