.WhatIsMarvel {
  .term-box {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      display: none;
      top: 17.5px;
      right: 0;
      width: 0px;
      height: 100%;
      border-left: 1px dashed var(--bs-secondary);
    }
    &::before {
      transform: scale(0.5);
    }

    &:nth-child(even) {
      &::before {
        @media (min-width: 992px) {
          left: -17.5px;
          right: auto;
        }
        background-color: var(--bs-info);
        box-shadow: 0 0 0 12.5px rgba(var(--bs-info-rgb), 0.5);
      }
      &::after {
        @media (min-width: 992px) {
          left: -0.5px;
          right: auto;
          display: block;
        }
      }
      p {
        @media (min-width: 992px) {
          text-align-last: end;
        }
      }
    }
    &:nth-child(odd) {
      &::before {
        @media (min-width: 992px) {
          left: auto;
          right: -17.5px;
        }
        background-color: var(--bs-primary);
        box-shadow: 0 0 0 12.5px rgba(var(--bs-primary-rgb), 0.5);
      }
      &::after {
        @media (min-width: 992px) {
          right: -0.5px;
          left: auto;
          display: block;
        }
      }
      p {
        @media (min-width: 992px) {
          text-align-last: start;
        }
      }
    }
    &:last-child::after {
      display: none;
    }
  }
}
