.TermsAndConditions {
  .section {
    > img {
      top: 0;
      left: 0;
    }
    ul {
      line-height: 225%;
      li {
        list-style: disc;
        &::marker {
          color: var(--bs-primary);
        }
        p {
          width: fit-content;
          text-align-last: center;
        }
      }
    }
  }
}
