#root {
  // max-width: 1800px;
  margin: 0 auto;
}
.font-fa {
  font-feature-settings: "ss02";
}
* {
  margin: 0;
  padding: 0;
  font-family: "iranSans";
  box-sizing: border-box;
  outline: none;
}
html,
body {
  font-size: 16px;
  background-color: $light;
  color: $dark;
  @extend .font-fa;
  overflow-x: hidden;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: inherit;
  }
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:disabled {
    pointer-events: all !important;
    cursor: not-allowed;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
p {
  margin-bottom: 0;
  color: $secondary;
  overflow-wrap: break-word;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.input-group-text {
  @extend .bg-transparent, .border-0;
  
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-menu {
  min-width: 100%;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.row {
  margin-left: auto;
  margin-right: auto;
  row-gap: 5px;
  @extend .flex-center;
}
.buttons {
  @extend .btn-group, .mx-auto, .d-flex, .w-100;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  > button {
    flex: 1;
    // max-width: 125px;
  }
}
.w-fit {
  width: fit-content;
}
.h-fit {
  height: fit-content;
}
.object-fit-contain,
.object-fit-cover {
  object-position: center;
}
.bi,
.bi::before {
  vertical-align: middle;
}
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
.bg-transparent {
  background-color: transparent !important;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.lh-normal {
  line-height: normal;
}
.overflow-wrap-break-word {
  overflow-wrap: break-word;
}
.text-last-center {
  text-align-last: center !important;
}
.btn-scroll-top {
  width: 40px;
  height: 40px;
  bottom: 12.5px;
  right: 12.5px;
  z-index: 1;
}
hr {
  @extend .bg-dark, .my-0, .mx-auto;
}
