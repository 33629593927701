.AccordionHover {
  > button {
    height: 50px;
    background-color: transparent;
    border: none;
  }
  > .content-section {
    max-height: 0;
    overflow: hidden;
    .content {
      > .horizontal-line {
        transform: scaleX(0);
      }
    }
    &.active {
      .content {
        > .horizontal-line {
          transform: scaleX(1);
        }
      }
    }
  }
}
