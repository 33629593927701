.SignUp {
    width: 90%;
    min-height: 100vh;
    position: relative;
    
    .SignUp-Overflow{
        width: 100%;
        height: auto;
        overflow-y: hidden;
        
    
        @media (min-width : 992px) {
            width: 60%;
            min-height: 700px;
            overflow-y: auto;
        }
    }
    min-height: 100vh;
    overflow-y: auto;
    padding: 50px 0px;
}