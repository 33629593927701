.Home {
  .main-section {
    
    .MainSection-img {
      position: relative;
      bottom: -48px;
      
      // @media (max-width : 1200px) {
      //   bottom: -48px;
      // }
    }
    .mainText{
      font-size: 30px;
      @media (min-width : 992px) {
        font-size: 50px;
      }
    }
  }

  .property {
    .card {
      background-color: transparent;
      height: 240px;

      &:hover {
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
      }
    }
  }

  // .guidance {
  //   .row > div {
  //     // min-height: 175px;
  //   }
  // }
  .TermAndConditions-home {
    margin: 50px 0px;

    .TermAndConditions-card {
      @media (min-width : 992px) {
        min-height: 650px;
      }

      height: auto;



      ul {
        line-height: 225%;

        li {
          list-style: disc;

          &::marker {
            color: black;
          }

          p {
            width: fit-content;
            text-align-last: start;
          }
        }
      }
    }


  }

  .JoinMarvelSchool {
    background-color: #E6F0EC;

    .slick-slide>div {
      margin: 5px;
    }

    .slick-list {
      margin: -5px;
    }
  }

  .UserSatisfaction {
    width: 100%;
    min-height: 650px;
    margin: 20px 0px;
    position: relative;
    left: 0px;
    right: 0px;

    .alien-div {
      width: 100%;
      display: inline-block;

      @media (min-width : 1200px) {
        width: 40%;
      }

      .alien-img {
        @media (min-width : 1200px) {
          width: 555px;
        }

        position: relative;
        width: 100%;
        height: 370px;
        background:radial-gradient(150% 300% at 0% 2.47%, rgba(0, 0, 0, 0) 100%, #000000 100%),
        radial-gradient(150.5% 118.61% at 120% -20%, rgba(0, 0, 0, 0) 65%, #000000 100%),
        url("../../../assets/images/home/alien.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }

    .Slider-div {
      position: relative;
      width: 100%;
      display: inline-block;

      @media (min-width : 1200px) {
        width: 60%;
      }

      .changedImage {
        transition: all 200ms ease-in-out;
        @media (min-width : 1024px) {
          width: 210px;
          height: 480px;
        }
        opacity: 1;
      }

      .notChangedImage {
        @media (min-width : 1024px){
          width: 160px;
          height: 360px;
          opacity: 1;
          filter: brightness(40%)
        }
      }

      .arrow-btn {
        position: absolute;

        @media (min-width : 992px) {
          right: 30%;
          top: 300px;
        }

        top : 40%;
        left: 10px;
      }

      &::after {
        content: "";

        @media (min-width : 992px) {
          display: block;
        }

        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100;
        width: 30%;
        background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.720151) 44.04%, rgba(0, 0, 0, 0) 100%);

      }
    }




  }

}