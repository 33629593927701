.AdminLayout {
  .sidebar-section {
    height: 100vh;
    .sidebar-menu {
      width: 100%;
      .accordionAdmin {
        --bs-bg-opacity: 0;
        border-radius: 1rem;
        background-color: transparent;
        transition: background-color 250ms;
    
        .accordionAdmin-toggler {
          color: white;
          border-radius: inherit;
          background-color: transparent;
    
          &::before,
          &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            pointer-events: none;
            display: none;
            
          }
    
          &::before {
            top: -30px;
            box-shadow: -15px 15px 0 0px white;
          }
    
          &::after {
            bottom: -30px;
            box-shadow: -15px -15px 0 0px white;
          }
        }
    
        .accordionAdmin-collapse {
          border-radius: inherit;
          height: 0;
          overflow: hidden;
          transition: 250ms linear;
    
          .accordionAdmin-body {
            padding: 0.5rem 1rem;
    
            div a i {
              opacity: 0;
              transition: 250ms;
              background-color: transparent;
              color: white;
            }
    
            div a.active i {
              opacity: 1;
            }
          }
        }
    
        &.active {
          --bs-bg-opacity: 0.25;
    
          .accordion-toggler {
            background-color: white;
            color: var(--bs-violet);
    
            &::before,
            &::after {
              display: block;
            }
          }
        }
      }
      .bg-img {
        opacity: 0.05;
      }
      a {
        --bs-bg-opacity: 0;
        transition: background-color 250ms;
        &:hover {
          --bs-bg-opacity: 0.15;
        }
        &.active {
          --bs-bg-opacity: 0.25;
          border-right: 5px solid;
        }
      }
    }
    .hide-btn {
      flex: 1;
      opacity: 0;
    }
  }
  > div > header {
    height: 60px;
  }
}
@media (max-width: 992px) {
  .AdminLayout {
    .sidebar-section {
      position: fixed;
      inset: 0;
      z-index: 10;
      transition: background-color 250ms;
      display: flex;
      pointer-events: none;
      background-color: transparent;
      .sidebar-menu {
        width: 300px;
        transition: transform 250ms;
        transform: translateX(100%);
      }
      &.active {
        pointer-events: all;
        background-color: rgba(0, 0, 0, 0.25);
        .sidebar-menu {
          transform: translateX(0);
        }
      }
    }
  }
}
