.ContactUs {
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 50%;
  //   height: 100%;
  //   background: linear-gradient(to left, transparent, var(--bs-light));
  //   opacity: 0.3;
  // }
  .main-section {
    > a {
      flex: 0 0 205px;
      width: 205px;
      height: 205px;
      border-style: dashed !important;
      @media (min-width: 1200px) {
        &:nth-child(1),
        &:nth-child(5) {
          margin-top: -4rem;
        }
        &:nth-child(2),
        &:nth-child(4) {
          margin-top: -2rem;
        }
      }
      span {
        font-size: 0.9rem;
      }
    }
  }
  form {
    // --bs-bg-opacity: 0.35;
    .Input {
      span {
        color: var(--bs-light) !important;
      }
    }
  }

  .getApp{
    margin: 40px 12px;
    background: #E6F0EC;
    min-height: 287px;
  }
}
