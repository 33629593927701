.CardServices{
    width: 90%;
    @media (min-width : 992px) {
        width: 250px;
    }
    height: 380px;
    border: 5px solid black;

    .CardServices-image{
        background-color: #212635;
    }
}