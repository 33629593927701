.Cart{
    .cart-header{
        height: 200px;
    }
    .card-body{
        position: relative;
        top: -150px;

        .cart-payment{
            position: relative;
            border: 3px solid black;
            border-top: none;
            top : -17px;
        }
    }
}