.LoginLayout {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    background-color: white;
    .loginRightSection {
        width: 100%;
        height: auto;
        overflow-y: hidden;
        background-color: white;
        position: relative;
        ::-webkit-scrollbar {
            width: 5px;
          }
          
          /* Track */
          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey; 
            border-radius: 0px;
            margin: 0px 5px;
          }
           
          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: rgb(23, 23, 23); 
            border-radius: 10px;
          }
          
          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #000000; 
          }
        
        
        
        @media (min-width : 992px) {
            width: 50%;
            height: 100vh;
            overflow-y: hidden;
            
        }
        
    }

    .loginLeftSection {
        height: auto;

        @media (min-width : 992px) {
            width: 50%;
            min-height: 100vh;
            height: auto;
        }

        .BeSure {
            min-width: 50%;
            min-height: 80px;
            @media (min-width : 1200px) {
                font-size: 14px;
                font-weight: 700;
            }
            
            border: 3px solid black;
            z-index: 2;
        }

        .arrowRightLong {
            position: absolute;
            top: 17%;
            left: 51%;
            z-index: 1;

        }

        .logoSection {
            z-index: 2;
            min-width: 135px;
            min-height: 135px;
            border-radius: 50%;
            background: rgba(167, 184, 235, 0.48);
            backdrop-filter: blur(8px);
        }

        .arrowDown {
            position: relative;
            right: 125px;
            top: -80px;
            z-index: 1;
        }

        .arrowRightShort {
            position: relative;
            top: 93px;
            right: 122px;

        }


        .currency-price {
            position: absolute;

            // @media (min-width : 1200px) {
            right: 22%;
            top: 20%;

            z-index: 2;
        }

        .ornament {
            display: none;

            @media (min-width : 1200px) {
                display: block;
            }

            position: absolute;
            bottom: 5px;
            left: -50px;
            z-index: 1;
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }

        .needLearning {
            position: absolute;
            bottom: 25px;
            right: 50px;

            .needLearning-card {
                border: 3px solid black;
                width: 265px;
                height: 121px;
                z-index: 2;
            }

            .needLearning-arrow {
                position: absolute;
                top: -115px;
                left: 50px;
                z-index: 0;
            }
        }
    }

}