.InputGroup {
  width: 100%;
  height: fit-content;
  padding-top: 18px;
  .label {
    top: calc(50% - 12px);
    right: 1rem;
    left: 1rem;
    z-index: 1;
    font-size: 1rem;
    transition: 250ms;
    position: absolute;
    pointer-events: none;
    color: var(--bs-secondary);
  }
  &.active,
  &.focused {
    .label {
      top: 0;
      right: 0.5rem;
      left: 0.5rem;
      font-size: 0.75rem;
      color: var(--bs-dark);
    }
  }
  .input-box {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: var(--bs-info);
      bottom: -1px;
      left: 0;
      transition: transform 500ms ease;
      transform: scaleX(0);
      transform-origin: 0 0;
    }
    width: 100%;
    min-height: 35px;
    input,
    textarea {
      flex: 1;
      outline: none;
      background-color: transparent;
      &::placeholder {
        text-align: start;
      }
    }
    textarea {
      min-height: 100px;
    }
  }
  .message {
    min-height: 15px;
    font-size: 0.6rem;
  }
  &.focused .input-box::before {
    transform-origin: 100% 0;
    transform: scaleX(1);
  }
}
