.CurrencyInfo {
    border: 3px solid black;

    @media (min-width:1200px) {
        width: 100%;
       
    }

    width: 100%;
    min-height:398px;
    z-index: 2;

    .CurrencyInfo-card {
        width: 103px;
        height: 65px;

        p {
            direction: ltr;
        }
    }
}