.ServerCard {
  border: 3px solid black;
  .usage-text {
    text-align-last: center;
  }
  @media (min-width: 992px) {
    height: 200px;
  }
  height: auto;
  > div {
    .interface-line-box {
      height: 100px;
      img {
        opacity: 0.25;
      }
    }
    // .price-box {
    //   @media (min-width: 992px) {
    //     height: 80%;
    //   }
    //   height: 350px;
    // }
  }
  &:hover {
    > div {
      .info-box {
        border-color: rgba(var(--bs-light-rgb), 1);
      }
      .interface-line-box {
        img {
          opacity: 1;
        }
      }
    }
  }
}
